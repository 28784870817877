import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78')
];

export const server_loads = [0,4,2,6,7];

export const dictionary = {
		"/(app)": [~42,[4]],
		"/(app)/(customer)/account": [~43,[4]],
		"/(admin)/admin": [~8,[2]],
		"/(admin)/admin/buchungen": [~9,[2]],
		"/(admin)/admin/buchungen/[internalId]": [~10,[2]],
		"/(admin)/admin/groupon": [~11,[2]],
		"/(admin)/admin/kontaktanfragen": [~12,[2]],
		"/(admin)/admin/kontaktanfragen/[id]": [~13,[2]],
		"/(admin)/admin/kursangebote": [~14,[2]],
		"/(admin)/admin/kursangebote/kalender": [~16,[2]],
		"/(admin)/admin/kursangebote/neu": [~17,[2]],
		"/(admin)/admin/kursangebote/[slug]": [~15,[2]],
		"/(admin)/admin/kurse": [~18,[2]],
		"/(admin)/admin/kurse/kategorien": [~21,[2]],
		"/(admin)/admin/kurse/kategorien/neu": [~22,[2]],
		"/(admin)/admin/kurse/neu": [~23,[2]],
		"/(admin)/admin/kurse/[slug]/bearbeiten": [~19,[2]],
		"/(admin)/admin/kurse/[slug]/dateien": [~20,[2]],
		"/(admin)/admin/nutzer": [~24,[2]],
		"/(admin)/admin/nutzer/[id]": [~25,[2]],
		"/(admin)/admin/unternehmen/fachbereiche": [~26,[2]],
		"/(admin)/admin/unternehmen/fachbereiche/neu": [~29,[2]],
		"/(admin)/admin/unternehmen/fachbereiche/[slug]": [~27,[2]],
		"/(admin)/admin/unternehmen/fachbereiche/[slug]/bearbeiten": [~28,[2]],
		"/(admin)/admin/unternehmen/institute": [~30,[2]],
		"/(admin)/admin/unternehmen/institute/neu": [~33,[2]],
		"/(admin)/admin/unternehmen/institute/[slug]": [~31,[2]],
		"/(admin)/admin/unternehmen/institute/[slug]/bearbeiten": [~32,[2]],
		"/(admin)/admin/unternehmen/standorte/details": [~34,[2,3]],
		"/(admin)/admin/unternehmen/standorte/details/neu": [~36,[2,3]],
		"/(admin)/admin/unternehmen/standorte/details/[id]/bearbeiten": [~35,[2,3]],
		"/(admin)/admin/unternehmen/standorte/gruppen": [~37,[2,3]],
		"/(admin)/admin/unternehmen/standorte/gruppen/neu": [~39,[2,3]],
		"/(admin)/admin/unternehmen/standorte/gruppen/[id]/bearbeiten": [~38,[2,3]],
		"/(admin)/admin/unternehmen/zoom": [~40,[2]],
		"/(admin)/admin/unternehmen/zoom/neu": [~41,[2]],
		"/(app)/(legal)/agb": [48,[4]],
		"/(app)/ausbildungen": [~52,[4,6]],
		"/(app)/ausbildungen/[courseCategory]": [~53,[4,6]],
		"/(app)/ausbildungen/[courseCategory]/[slug]": [~54,[4]],
		"/(app)/ausbildungen/[courseCategory]/[slug]/bewertungen": [~55,[4]],
		"/(app)/blog": [56,[4]],
		"/(app)/(customer)/buchungen": [~44,[4,5]],
		"/(app)/(customer)/buchungen/dokumente": [~46,[4,5]],
		"/(app)/(customer)/buchungen/kalender": [~47,[4,5]],
		"/(app)/(customer)/buchungen/[id]": [~45,[4,5]],
		"/(app)/(legal)/datenschutz": [49,[4]],
		"/(auth)/email-verifizieren": [~67],
		"/(app)/(legal)/impressum": [50,[4]],
		"/(app)/kontakt": [~57,[4]],
		"/(auth)/login": [~68],
		"/(app)/partner": [58,[4]],
		"/(app)/partner/sportspass": [~59,[4]],
		"/(app)/partner/sportspass/[slug]": [~60,[4]],
		"/(auth)/passwort-reset": [~69],
		"/(auth)/passwort-reset/[token]": [~70],
		"/(teacher)/referenten/account": [~72,[7]],
		"/(teacher)/referenten/kursangebote": [~73,[7]],
		"/(teacher)/referenten/kursangebote/[id]": [~74,[7]],
		"/(teacher)/referenten/kursangebote/[id]/anwesenheit": [~75,[7]],
		"/(teacher)/referenten/kurse": [~76,[7]],
		"/(teacher)/referenten/profil": [~77,[7]],
		"/(teacher)/referenten/uebersicht": [~78,[7]],
		"/(auth)/registrieren": [~71],
		"/(app)/standorte": [~61,[4]],
		"/(app)/standorte/[slug]": [~62,[4]],
		"/(app)/team": [~63,[4]],
		"/(app)/team/[slug]": [~64,[4]],
		"/(app)/warenkorb": [~65,[4]],
		"/(app)/warenkorb/kasse": [~66],
		"/(app)/(legal)/widerrufsbelehrung": [51,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';